export const formatMoney = (num) => {
    if (!num || num === "") return "0";
    let numberMoney = Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    numberMoney = (numberMoney + '').replace(/,/g, '*');
    numberMoney = numberMoney.replace(/\./g, ',');
    numberMoney = numberMoney.replace(/\*/g, '.');
    return numberMoney;
};

export const trimText = (str) => {
    let countText = str.length;
    const lastSpace = str.lastIndexOf(' ');
    if (countText > 0 && lastSpace === -1) {
        return str = str.slice(0);
    }
    return str.slice(lastSpace);
};

export const validatePhoneNumber = (number) =>{
     const re = /^((\+)84|0)[1-9](\d{2}){4}$/;
    return re.test(number);
};

export const validateEmailWithoutSpecial = email => {
  // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  let re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  return re.test(email.toLowerCase());
};
