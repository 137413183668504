import { fetchApi } from "../../utils/api";
export async function getListJob(payload, dispatch) {
    try {
        let response = await fetchApi(`/app/job/get-list-job`, 'get', payload);
        if (response.code !== 200) {
            return {
                data: {},
            };
        }
        return response.data;
    } catch (error) {
        console.log(error);
        return {
            rows: [],
        };
    }
}