import { message } from "antd";
import { fetchApiUpload, fetchApi } from "../../utils/api";

export const uploadOneFile = async (payload) => {
    try {
        const response = await fetchApiUpload(
            `/api/uploadImage`,
            "post",
            payload,
        );

        if (response.code === 400) {
            message.error(response?.message);
            setTimeout(() => {
                message.destroy();
            }, 2000);
            return;
        }
        return response?.data?.filePaths?.file;
    } catch (error) {
        alert(error || error?.message);
        console.log("uploadOneFileError", error);
    }
};

export const uploadFileContact = async (payload) => {
    try {
        const response = await fetchApiUpload(
            `/api/uploadFileContact`,
            "post",
            payload,
        );

        if (response.code === 400) {
            message.error(response?.message);
            setTimeout(() => {
                message.destroy();
            }, 2000);
            return;
        }
        return response?.data?.filePaths?.file;
    } catch (error) {
        alert(error || error?.message);
        console.log("uploadFileContactError", error);
    }
};

export async function getImageOffice() {
    try {
        let response = await fetchApi(`/app/image-office/get-list-image-office`, "get");
        if (response.code !== 200) {
            return [];
        }
        return response.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}