import Head from 'next/head';
export default function MetaSeo(props) {
  let { url, type, title, description, image, domain, card, keywords } = props;
  if (!url) {
    url = 'https://tcom.vn';
  }
  if (!type) {
    type = 'website';
  }
  if (!title) {
    title = 'TCOM Corporation';
  }
  if (!description) {
    description =
      'TCOM is a leading technology company in Vietnam, specializing in Digital Transformation, IT System Consulting, Offshore Services, and Developing cutting-edge technologies such as AIOT and Blockchain';
  }
  if (!image) {
    image = 'https://tcom.vn/assets/images/tcom.jpg';
  }
  if (!domain) {
    domain = 'tcom.vn';
  }
  if (!card) {
    card = 'summary_large_image';
  }
  if (!keywords) {
    keywords =
      'DX, Digital Transformation, Leading IT company in Vietnam , Outsourcing IT service in Vietnam, Offshore, Outsourcing, Artificial Intelligence, AI, AIOT, Blockchain, AR/VR, Livestreaming';
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name='news_keywords' content={keywords} />
        <meta property='og:url' content={url} />
        <meta property='og:type' content={type} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={image} />
        <meta property='keywords' content={keywords} />
        <meta name='twitter:card' content={card} />
        <meta property='twitter:domain' content={domain} />
        <meta property='twitter:url' content={url} />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:image' content={image} />
      </Head>
    </>
  );
}
